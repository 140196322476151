// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*Lista autorizados*/
.lista-personas {
 /* max-height: 400px;*/ /* Altura máxima para el scroll vertical */
  overflow-y: auto; /* Habilitar scroll vertical cuando la lista sea larga */
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #f5f5f5;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #007bff; /* Azul */
  color: #fff;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

`, "",{"version":3,"sources":["webpack://./src/css/Autorizados.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;CACC,sBAAsB,EAAE,0CAA0C;EACjE,gBAAgB,EAAE,wDAAwD;EAC1E,sBAAsB;EACtB,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;;EAEE,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,yBAAyB,EAAE,SAAS;EACpC,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/*Lista autorizados*/\n.lista-personas {\n /* max-height: 400px;*/ /* Altura máxima para el scroll vertical */\n  overflow-y: auto; /* Habilitar scroll vertical cuando la lista sea larga */\n  border: 1px solid #ccc;\n  padding: 10px;\n  background-color: #f5f5f5;\n}\n\ntable {\n  width: 100%;\n  border-collapse: collapse;\n  margin-top: 10px;\n}\n\nth,\ntd {\n  border: 1px solid #ddd;\n  padding: 8px;\n  text-align: left;\n}\n\nth {\n  background-color: #007bff; /* Azul */\n  color: #fff;\n}\n\ntr:nth-child(even) {\n  background-color: #f2f2f2;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
