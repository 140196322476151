// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .inputLogin{
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  .logo {
    display: flex;
    align-items:center;
   
  }
  
  .logo img {
    max-width: 100px; /* Ajusta el tamaño del logo según tu preferencia */
    margin-right: 10px; /* Espacio entre el logo y el texto */
  }
  h2{
    color:#2980B9;
  }`, "",{"version":3,"sources":["webpack://./src/css/Login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;EACf;EACA;IACE,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,sBAAsB;EACxB;EACA;IACE,aAAa;IACb,kBAAkB;;EAEpB;;EAEA;IACE,gBAAgB,EAAE,mDAAmD;IACrE,kBAAkB,EAAE,qCAAqC;EAC3D;EACA;IACE,aAAa;EACf","sourcesContent":[".login{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n  }\n  .inputLogin{\n    padding: 10px;\n    margin-bottom: 10px;\n    border-radius: 4px;\n    border: 1px solid #ccc;\n  }\n  .logo {\n    display: flex;\n    align-items:center;\n   \n  }\n  \n  .logo img {\n    max-width: 100px; /* Ajusta el tamaño del logo según tu preferencia */\n    margin-right: 10px; /* Espacio entre el logo y el texto */\n  }\n  h2{\n    color:#2980B9;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
